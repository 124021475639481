var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Deposit" }, [
    _c("div", { staticClass: "bg" }),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("headbox", {
          attrs: {
            title: "交押金",
            isbackPath: true,
            path: "pricingDeposit",
            bgColor: "#fff"
          }
        }),
        _c("div", { staticClass: "bigBox" }, [
          _c("div", { staticClass: "box bank flex" }, [
            _c("div", { staticClass: "bankIcon" }),
            _vm._m(0),
            _c(
              "div",
              { staticClass: "radio" },
              [
                _c("el-checkbox", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.checked,
                    callback: function($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "box bankInfo" },
            [
              _c(
                "div",
                {
                  staticClass: "bankCard",
                  class: {
                    ICBCbankBg: _vm.bankBg.ICBC,
                    ABCbankBg: _vm.bankBg.ABC,
                    BOCbankBg: _vm.bankBg.BOC,
                    CMBbankBg: _vm.bankBg.CMB,
                    CCBbankBg: _vm.bankBg.CCB,
                    PABbankBg: _vm.bankBg.PAB
                  }
                },
                [
                  _c("div", { staticClass: "bankName flex" }, [
                    _c("div", { staticClass: "name" }, [
                      _c("h4", {}, [_vm._v(_vm._s(_vm.Banklist.name))]),
                      _c("p", [_vm._v(_vm._s(_vm.Banklist.address))])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "capy",
                        on: {
                          click: function($event) {
                            return _vm.copy(_vm.Banklist.name)
                          }
                        }
                      },
                      [_vm._v("复制")]
                    )
                  ]),
                  _c("div", { staticClass: "CardNumber flex_between" }, [
                    _c("div", [_vm._v(_vm._s(_vm.Banklist.num))]),
                    _c(
                      "div",
                      {
                        staticClass: "capyCard",
                        on: {
                          click: function($event) {
                            return _vm.copy(_vm.Banklist.num)
                          }
                        }
                      },
                      [_vm._v("复制")]
                    )
                  ])
                ]
              ),
              _c("div", { staticClass: "bankTip" }, [
                _vm._v(
                  " 您必须向发卡银行确认您的银联卡是否已开通网上支付业务功能，才可以选择此方式付款，请您在支付后上传付款截图。 "
                )
              ]),
              _c("van-field", {
                attrs: {
                  type: "digit",
                  border: false,
                  label: "金额:",
                  placeholder: "仅支持整千金额"
                },
                model: {
                  value: _vm.edpositNb,
                  callback: function($$v) {
                    _vm.edpositNb = $$v
                  },
                  expression: "edpositNb"
                }
              }),
              _c("h4", { staticClass: "screenshot" }, [
                _vm._v("上传转账截图：")
              ]),
              _c(
                "van-uploader",
                {
                  attrs: {
                    "after-read": _vm.afterRead,
                    multiple: "",
                    "max-count": 3,
                    "preview-size": "90",
                    border: "false"
                  },
                  model: {
                    value: _vm.fileList,
                    callback: function($$v) {
                      _vm.fileList = $$v
                    },
                    expression: "fileList"
                  }
                },
                [
                  _c("div", { staticClass: "file" }, [
                    _c("div", { staticClass: "one" }),
                    _c("div", { staticClass: "two" })
                  ])
                ]
              ),
              _c("div", { staticClass: "tips" }, [
                _vm._v("温馨提示：转款不要备注，谢谢！")
              ])
            ],
            1
          ),
          _c("footer", { staticClass: "flex" }, [
            _c("div", { staticClass: "btn close", on: { click: _vm.close } }, [
              _vm._v("取消")
            ]),
            _c(
              "div",
              { staticClass: "btn confirm", on: { click: _vm.confirm } },
              [_vm._v("确认")]
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bankText" }, [
      _c("h4", [_vm._v("银行转账")]),
      _c("p", [_vm._v("使用网银支付转账，无手续费，需人工确认")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }